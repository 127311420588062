import * as React from "react";
const StartSVG = () => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        xmlnsXlink="http://www.w3.org/1999/xlink"
        viewBox="0 0 64 64"
        xmlSpace="preserve"
        className="h-full"
    >
        <style type="text/css">
            {
                "\n\t.st0{fill:#474747;}\n\t.st1{opacity:0.2;}\n\t.st2{fill:#231F20;}\n\t.st3{fill:#C75C5C;}\n\t.st4{fill:#E0995E;}\n\t.st5{fill:#F5CF87;}\n\t.st6{fill:#E0E0D1;}\n"
            }
        </style>
        <g id="Layer_1">
            <g>
                <circle className="st0" cx={32} cy={32} r={32} />
            </g>
            <g className="st1">
                <path
                    className="st2"
                    d="M32.1,39.7C30.5,41.2,26,52.2,26,52.2c-0.7,1.2,0.3,2.1,2.6,0.9l8.1-4.4c4.4-2.4,5.2-3.1,5.2-8l-0.8-5.9 c-0.3-2-1.8-2.4-3.3-0.8L32.1,39.7z"
                />
            </g>
            <g className="st1">
                <path
                    className="st2"
                    d="M27.1,34.7c-1.6,1.6-12.5,6.1-12.5,6.1c-1.2,0.7-2.1-0.3-0.9-2.6L18,30c2.4-4.4,3.1-5.2,8-5.2l5.9,0.8 c2,0.3,2.4,1.8,0.8,3.3L27.1,34.7z"
                />
            </g>
            <g>
                <path
                    className="st3"
                    d="M32.1,37.7C30.5,39.2,26,50.2,26,50.2c-0.7,1.2,0.3,2.1,2.6,0.9l8.1-4.4c4.4-2.4,5.2-3.1,5.2-8l-0.8-5.9 c-0.3-2-1.8-2.4-3.3-0.8L32.1,37.7z"
                />
            </g>
            <g>
                <path
                    className="st3"
                    d="M27.1,32.7c-1.6,1.6-12.5,6.1-12.5,6.1c-1.2,0.7-2.1-0.3-0.9-2.6L18,28c2.4-4.4,3.1-5.2,8-5.2l5.9,0.8 c2,0.3,2.4,1.8,0.8,3.3L27.1,32.7z"
                />
            </g>
            <g className="st1">
                <path
                    className="st2"
                    d="M21.2,39.5c5.1-5.1,9.3-3.5,9.3-3.5s1.5,4.3-3.5,9.3s-13.3,8.8-13.9,8.1C12.5,52.9,16.2,44.6,21.2,39.5z"
                />
            </g>
            <g>
                <path
                    className="st4"
                    d="M21.2,37.5c5.1-5.1,9.3-3.5,9.3-3.5s1.5,4.3-3.5,9.3s-13.3,8.8-13.9,8.1C12.5,50.9,16.2,42.6,21.2,37.5z"
                />
            </g>
            <g>
                <path
                    className="st5"
                    d="M25.5,33.3c5.1-5.1,9.3-3.5,9.3-3.5s1.5,4.3-3.5,9.3S18,47.9,17.4,47.2C16.7,46.6,20.4,38.4,25.5,33.3z"
                />
            </g>
            <g className="st1">
                <path
                    className="st2"
                    d="M43.6,37.1c-7.8,7.8-19.8,5.7-19.8,5.7s-2.1-12,5.7-19.8s19.6-8.6,21.2-7.1C52.2,17.4,51.4,29.3,43.6,37.1z"
                />
            </g>
            <g>
                <path
                    className="st6"
                    d="M43.6,35.1c-7.8,7.8-19.8,5.7-19.8,5.7s-2.1-12,5.7-19.8s19.6-8.6,21.2-7.1C52.2,15.4,51.4,27.3,43.6,35.1z"
                />
            </g>
            <g className="st1">
                <path
                    className="st2"
                    d="M21,47c-0.8,0.8-1.3,1.5-2.1,0.7l0,0c-0.8-0.8-0.1-1.3,0.7-2.1l9.9-12.7c0.8-0.8,4.2-1.5,4.9-0.7l0,0 c0.8,0.8,0.1,4.2-0.7,4.9L21,47z"
                />
            </g>
            <g>
                <path
                    className="st3"
                    d="M21,45c-0.8,0.8-1.3,1.5-2.1,0.7l0,0c-0.8-0.8-0.1-1.3,0.7-2.1l9.9-12.7c0.8-0.8,4.2-1.5,4.9-0.7l0,0 c0.8,0.8,0.1,4.2-0.7,4.9L21,45z"
                />
            </g>
            <g className="st1">
                <circle className="st2" cx={39.6} cy={27.1} r={4} />
            </g>
            <g className="st1">
                <circle className="st2" cx={45.3} cy={21.5} r={2} />
            </g>
            <g>
                <circle className="st0" cx={39.6} cy={25.1} r={4} />
            </g>
            <g>
                <circle className="st0" cx={45.3} cy={19.5} r={2} />
            </g>
        </g>
        <g id="Layer_2" />
    </svg>
);
export default StartSVG;