import images from "../images copy/alok_sir.jpg";

export const slidesData = [
    {
        image: require("../images copy/image1.png"),
        name: "Alok Halder",

        title: "Founder"
    },
    {
        image: require("../images copy/image2.png"),
        name: "Alok Halder",

        title: "Founder"
    },
    {
        image: require("../images copy/image3.png"),
        name: "Alok Halder",

        title: "Founder"
    },
];
