import * as React from "react";

const LearningSVG = () => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        xmlnsXlink="http://www.w3.org/1999/xlink"
        id="Capa_1"
        x="0px"
        y="0px"
        viewBox="0 0 455 455"
        style={{
            enableBackground: "new 0 0 455 455",
        }}
        xmlSpace="preserve"
        className="h-full"
    >
        <g>
            <path
                style={{
                    fill: "#98D9D5",
                }}
                d="M455,92.5v330H0v-330l227.5-20L455,92.5z"
            />
            <path
                style={{
                    fill: "#FFFFFF",
                }}
                d="M425,122.5v270H30v-270H425z"
            />
            <rect
                x={70}
                y={172.5}
                style={{
                    fill: "#F2EBD9",
                }}
                width={215}
                height={170}
            />
            <path
                style={{
                    fill: "#6DA8D6",
                }}
                d="M455,32.5v60H0v-60H455z M430,62.5c0-4.14-3.36-7.5-7.5-7.5s-7.5,3.36-7.5,7.5s3.36,7.5,7.5,7.5   S430,66.64,430,62.5z M395,62.5c0-4.14-3.36-7.5-7.5-7.5s-7.5,3.36-7.5,7.5s3.36,7.5,7.5,7.5S395,66.64,395,62.5z M360,62.5   c0-4.14-3.36-7.5-7.5-7.5s-7.5,3.36-7.5,7.5s3.36,7.5,7.5,7.5S360,66.64,360,62.5z"
            />
            <circle
                style={{
                    fill: "#274B6D",
                }}
                cx={387.5}
                cy={62.5}
                r={7.5}
            />
            <circle
                style={{
                    fill: "#274B6D",
                }}
                cx={422.5}
                cy={62.5}
                r={7.5}
            />
            <circle
                style={{
                    fill: "#274B6D",
                }}
                cx={352.5}
                cy={62.5}
                r={7.5}
            />
            <path
                style={{
                    fill: "#6DA8D6",
                }}
                d="M385,197.5h-60c-4.143,0-7.5-3.357-7.5-7.5s3.357-7.5,7.5-7.5h60c4.143,0,7.5,3.357,7.5,7.5   S389.143,197.5,385,197.5z"
            />
            <path
                style={{
                    fill: "#6DA8D6",
                }}
                d="M385,242.5h-60c-4.143,0-7.5-3.357-7.5-7.5s3.357-7.5,7.5-7.5h60c4.143,0,7.5,3.357,7.5,7.5   S389.143,242.5,385,242.5z"
            />
            <path
                style={{
                    fill: "#6DA8D6",
                }}
                d="M385,287.5h-60c-4.143,0-7.5-3.357-7.5-7.5s3.357-7.5,7.5-7.5h60c4.143,0,7.5,3.357,7.5,7.5   S389.143,287.5,385,287.5z"
            />
            <path
                style={{
                    fill: "#6DA8D6",
                }}
                d="M385,332.5h-60c-4.143,0-7.5-3.357-7.5-7.5s3.357-7.5,7.5-7.5h60c4.143,0,7.5,3.357,7.5,7.5   S389.143,332.5,385,332.5z"
            />
            <polygon
                style={{
                    fill: "#F2484B",
                }}
                points="127.5,307.5 127.5,207.5 227.5,257.5  "
            />
        </g>
    </svg>
);
export default LearningSVG;