import * as React from "react";

const PurchaseSVG = () => (
    <svg
        id="Layer_1"
        xmlns="http://www.w3.org/2000/svg"
        xmlnsXlink="http://www.w3.org/1999/xlink"
        x="0px"
        y="0px"
        viewBox="0 0 460.003 460.003"
        style={{
            enableBackground: "new 0 0 460.003 460.003",
        }}
        xmlSpace="preserve"
        className="h-full"
    >
        <g>
            <path
                style={{
                    fill: "#366796",
                }}
                d="M126.731,24.436C51.568,62.27,0.002,140.115,0.002,230.003c0,98.564,61.999,182.647,149.126,215.379 L126.731,24.436z"
            />
            <path
                style={{
                    fill: "#273B7A",
                }}
                d="M334.427,25.024C334.401,25.03,65.939,362.17,65.939,362.17l83.2,83.199 c25.171,9.453,52.41,14.634,80.863,14.634c127.025,0,230-102.975,230-230C460.002,140.576,408.963,63.072,334.427,25.024z"
            />
            <polygon
                style={{
                    fill: "#FEE187",
                }}
                points="211.245,58.218 126.741,72.08 126.741,220.668 295.914,220.668 334.401,130.12  "
            />
            <polygon
                style={{
                    fill: "#71E2F0",
                }}
                points="216.656,244.479 230.502,348.319 380.886,348.319 380.886,258.325  "
            />
            <polygon
                style={{
                    fill: "#C2FBFF",
                }}
                points="230.502,168.326 216.656,258.325 380.886,258.325 380.886,168.331  "
            />
            <polygon
                style={{
                    fill: "#C2FBFF",
                }}
                points="79.786,258.658 79.786,348.653 230.502,348.319 230.502,258.325 161.271,237.556  "
            />
            <polygon
                style={{
                    fill: "#FFFFFF",
                }}
                points="79.786,168.664 79.786,258.658 230.502,258.325 230.502,168.326  "
            />
            <path
                style={{
                    fill: "#38C6D9",
                }}
                d="M230.502,208.331c28.677,0,51.92,23.243,51.92,51.92c0,28.671-23.243,51.919-51.92,51.919 l-11.538-51.919L230.502,208.331z"
            />
            <path
                style={{
                    fill: "#71E2F0",
                }}
                d="M230.502,208.331V312.17c-28.677,0-51.919-23.248-51.919-51.919 C178.583,231.573,201.826,208.331,230.502,208.331z"
            />
            <path
                style={{
                    fill: "#38C6D9",
                }}
                d="M342.468,178.331H230.502l-10-10.005l10-9.929h164.23V362.17h-164.23l-10-9.232l10-10.768h111.966 c0-17.819,14.445-32.263,32.263-32.263v-99.313C356.913,210.594,342.468,196.149,342.468,178.331z"
            />
            <path
                style={{
                    fill: "#71E2F0",
                }}
                d="M118.203,178.331h112.299v-19.933H65.94V362.17h164.563v-20h-112.3 c0-17.819-14.445-31.93-32.263-31.93v-99.313C103.758,210.927,118.203,196.149,118.203,178.331z"
            />
            <path
                style={{
                    fill: "#FFC61B",
                }}
                d="M288.576,158.397l0.355,78.119c0,12.406,10.094,22.5,22.5,22.5s22.5-10.094,22.5-22.5V72.08 L211.245,58.218l0.001,13.863C211.245,117.032,245.051,153.031,288.576,158.397L288.576,158.397z"
            />
            <path
                style={{
                    fill: "#121149",
                }}
                d="M334.401,25.03C303.069,9.038,267.596,0,230.006,0l-23.539,33.806L230.15,72.08h104.25V25.03z"
            />
            <path
                style={{
                    fill: "#273B7A",
                }}
                d="M126.741,72.08h103.41L230.006,0c-37.137,0-72.21,8.818-103.265,24.455 C126.741,24.455,126.741,72.08,126.741,72.08z"
            />
        </g>
    </svg>
);
export default PurchaseSVG;