import * as React from "react";
const TrustSVG = (props) => (
    <svg
        viewBox="0 0 72 72"
        id="emoji"
        xmlns="http://www.w3.org/2000/svg"
        className="h-full"
    >
        <g id="color">
            <path
                fill="#D0CFCE"
                d="M35.67,60.98C10.08,54.96,11,16.06,11,16.06c4.75,0.05,25-5.08,25-5.08v0.09c0,0,20.25,5.12,25,5.08 C61,16.15,61.25,54.96,35.67,60.98z"
            />
            <path
                fill="#9B9B9A"
                d="M46.5557,13.5874C46.5557,13.5874,53,44,36,60c0,0,16-1,22-25s0-19,0-19L46.5557,13.5874"
            />
            <polygon
                fill="#61B2E4"
                points="26.6438,32.8294 35.8089,46.8398 50.5128,22.5755 45.4101,25.0669 35.8409,40.858 29.8763,31.7401"
            />
        </g>
        <g id="hair" />
        <g id="skin" />
        <g id="skin-shadow" />
        <g id="line">
            <path
                fill="none"
                stroke="#000000"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeMiterlimit={10}
                strokeWidth={2}
                d="M36,10.9792c0,0-20.2537,5.1291-25,5.0853c0,0-0.9167,38.8939,24.6667,44.9147"
            />
            <path
                fill="none"
                stroke="#000000"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeMiterlimit={10}
                strokeWidth={2}
                d="M35.6667,60.9792C61.25,54.9584,61,16.151,61,16.151c-4.7463,0.0437-25-5.0853-25-5.0853"
            />
            <polygon
                fill="none"
                stroke="#000000"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeMiterlimit={10}
                strokeWidth={2}
                points="26.6438,32.8294 35.8089,46.8398 50.5128,22.5755 45.4101,25.0669 35.8409,40.858 29.8763,31.7401"
            />
        </g>
    </svg>
);
export default TrustSVG;