import * as React from "react";

const DownSVG = () => (
    <svg
        fill="#27272A"
        viewBox="0 -6 524 524"
        xmlns="http://www.w3.org/2000/svg"
        className="h-3 "
    >
        <title>{"down"}</title>
        <path d="M64 191L98 157 262 320 426 157 460 191 262 387 64 191Z" />
    </svg>
);
export default DownSVG;